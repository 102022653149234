<template>
  <div class="edit_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">新增地址</div>
      <div>
        <van-icon name="arrow-left"  size="24" color="#fff"/>
      </div>
    </div>
    <div style="overflow-y:auto;">
      <van-address-edit
      :area-list="areaList"
      show-set-default
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @change-detail="onChangeDetail"
      
    />
    </div>
    
    <div class="address_bottom_1">
      <div class="btn" @click="emitConfirm">保存</div>
    </div>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      areaList,
      searchResult: []
    };
  },
  methods: {
    emitConfirm(){
      let el = window.document.getElementsByClassName('van-address-edit__buttons')
      console.log(el)
      el[0].childNodes[0].click()
    },
    onSave(content) {
      //Toast("save");
      let addressForm = {
        id: this.$getUser()?.id,
        name: content.name,
        tel: content.tel,
        province: content.province,
        city: content.city,
        county: content.county,
        address:content.addressDetail,
        default: content.isDefault,
        province_code: content.areaCode.slice(0,2),
        city_code: content.areaCode.slice(2,4),
        county_code: content.areaCode.slice(4,6)
      };
      this.addAddress(addressForm)
    },
    onDelete() {
      //Toast("delete");
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: "黄龙万科中心",
            address: "杭州市西湖区"
          }
        ];
      } else {
        this.searchResult = [];
      }
    },
    async addAddress(addressForm) {
      let { data: res } = await this.$http.post("api/addAddress", addressForm);
      if (res.statusCode !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.$toast.success("修改成功");
      this.$router.go(-1)
    }
  }
};
</script>

<style lang="less" >
.van-address-list{
    overflow-y: auto !important;
  }
.edit_box {
  text-align: left;
  background-color: #f9f9f9;
  height: calc(100vh);
  padding-top:5.5rem;
  
  .van-switch--on{
    background-color: #F6736E;
  }
  .van-address-edit__buttons{
    display: none;
  }
  .address_bottom_1{
    position: fixed;
    top: 600px;
    left:0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .btn{
      width: 12.5rem;
      height: 2rem;
      color: #fff;
      font-size: 1rem;

      background-color:var(--fc--);
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>